import React from 'react';
import {
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  IconButton,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
  Button,
  Drawer,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import BusinessIcon from '@mui/icons-material/Business';
import MailIcon from '@mui/icons-material/Mail';
import { useTheme } from '@mui/material/styles';
import GenplsIcon from '../assets/genpls_logo.webp';
import { useTranslation } from 'react-i18next';

const Header: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = React.useState(i18n.language);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  React.useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  const handleDrawerClose = () => {
    setIsMenuOpen(false);
  };

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleSetLang = (event: SelectChangeEvent) => {
    setLang(event.target.value);
  };

  interface ListProps {
    name: string;
    href: string;
    icon: React.ReactNode;
  }
  const HeaderListItem = (props: ListProps) => {
    const iconColor = useTheme().palette.secondary.main;
    let listItemIcon;
    if (props.icon) {
      listItemIcon = (
        <ListItemIcon style={{ color: iconColor }}>{props.icon}</ListItemIcon>
      );
    } else {
      listItemIcon = null;
    }
    return (
      <ListItem disablePadding>
        <ListItemButton
          color="secondary"
          onClick={() => {
            handleDrawerClose();
            window.location.href = props.href;
          }}
          sx={{
            whiteSpace: 'nowrap',
          }}
        >
          {listItemIcon}
          <ListItemText
            primary={props.name}
            primaryTypographyProps={{
              color: 'secondary',
              fontWeight: 'bold',
            }}
          />
        </ListItemButton>
      </ListItem>
    );
  };

  const SwitchLangButton = () => {
    return (
      <FormControl size="small">
        <InputLabel
          sx={{
            color: useTheme().palette.secondary.main,
          }}
        >
          Language
        </InputLabel>
        <Select
          labelId="language-switching"
          value={lang}
          label="Language"
          onChange={handleSetLang}
          sx={{
            fontWeight: 'bold',
            color: useTheme().palette.secondary.main,
            '& fieldset': {
              borderColor: useTheme().palette.secondary.main,
            },
          }}
        >
          <MenuItem value={'en'}>English</MenuItem>
          <MenuItem value={'ja'}>日本語</MenuItem>
        </Select>
      </FormControl>
    );
  };

  return (
    <>
      <AppBar position="fixed" sx={{ bgcolor: 'primary.main' }}>
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            onClick={() => {
              if (window.location.pathname == '/') {
                window.location.href = `/#home`;
                window.history.replaceState('', '', '/');
              } else {
                window.location.href = `/`;
              }
            }}
          >
            <Box
              component="img"
              sx={{
                width: { xs: '7vw', sm: 40 },
                bgcolor: 'secondary.main',
              }}
              alt="Genpls"
              src={GenplsIcon}
            />
          </Button>
          <List
            sx={{
              display: {
                xs: 'none',
                sm: 'none',
                md: 'flex',
              },
            }}
          >
            <HeaderListItem name={t('nav.home')} href="/#home" icon={null} />
            <HeaderListItem
              name={t('nav.reservation')}
              href="/#reservation"
              icon={null}
            />
            <HeaderListItem
              name={t('nav.service')}
              href="/#service"
              icon={null}
            />
            <HeaderListItem name={t('nav.price')} href="/#price" icon={null} />
            <HeaderListItem
              name={t('nav.access')}
              href="/#access"
              icon={null}
            />
            <HeaderListItem
              name={t('nav.contact')}
              href="/contact-form"
              icon={null}
            />
            <ListItem>
              <SwitchLangButton />
            </ListItem>
          </List>
          <IconButton
            color="secondary"
            aria-label="menu"
            onClick={handleMenuToggle}
            sx={{
              display: { md: 'none' },
            }}
          >
            {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="right"
        open={isMenuOpen}
        onClose={handleDrawerClose}
        sx={{
          zIndex: 1000,
          '& .MuiDrawer-paper': {
            minWidth: '55vw',
            paddingTop: '2vw',
            top: '56px',
            bgcolor: 'primary.main',
          },
        }}
      >
        <List>
          <HeaderListItem
            name={t('nav.home')}
            href="/#home"
            icon={<HomeIcon />}
          />
          <HeaderListItem
            name={t('nav.reservation')}
            href="/#reservation"
            icon={<MonitorHeartIcon />}
          />
          <HeaderListItem
            name={t('nav.service')}
            href="/#service"
            icon={<MonitorHeartIcon />}
          />
          <HeaderListItem
            name={t('nav.price')}
            href="/#price"
            icon={<MonitorHeartIcon />}
          />
          <HeaderListItem
            name={t('nav.access')}
            href="/#access"
            icon={<BusinessIcon />}
          />
          <HeaderListItem
            name={t('nav.contact')}
            href="/contact-form"
            icon={<MailIcon />}
          />
        </List>
        <Box m={'1rem auto'}>
          <SwitchLangButton />
        </Box>
      </Drawer>
    </>
  );
};

export default Header;
