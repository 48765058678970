import React from 'react';
import { Box, Container } from '@mui/material';
import HomeSection from '../components/HomeSection';
import ReservationSection from '../components/ReservationSection';
import ServiceSection from '../components/ServiceSection';
import PriceSection from '../components/PriceSection';
import AccessSection from '../components/AccessSection';
import ContactSection from '../components/ContactSection';
import Header from '../components/Header';
import Footer from '../components/Footer';

const HomePage: React.FC = () => {
  return (
    <>
      <Header />
      <Box id="home">
        <HomeSection />
      </Box>
      <Container maxWidth="md">
        <Box mt={{ xs: 6, sm: 8 }}>
          <Box id="reservation">
            <ReservationSection />
          </Box>
          <Box id="service">
            <ServiceSection />
          </Box>
          <Box id="price">
            <PriceSection />
          </Box>
          <Box id="access">
            <AccessSection />
          </Box>
          <Box id="contact">
            <ContactSection />
          </Box>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default HomePage;
