import React from 'react';
import { Box, Typography, Grid, Paper, Link } from '@mui/material';
import MadoriImage from '../assets/madori.webp';
import WhiteBoardImage from '../assets/white_board.webp';
import ProjectorImage from '../assets/projector.webp';
import LockerImage from '../assets/locker.webp';
import AirConditioningImage from '../assets/air_conditioning.webp';
import RestRoomImage from '../assets/rest_room.webp';
import PowerStrip1Image from '../assets/power_strip_1.webp';
import PowerStrip2Image from '../assets/power_strip_2.webp';
import MultifunctionDeviceImage from '../assets/multifunction_device.webp';
import WifiImage from '../assets/wifi.webp';
import EntranceImage from '../assets/entrance.webp';
import { GoldenRatioImage } from '../styles/commonStyles';
import { useTranslation } from 'react-i18next';

const ServiceSection: React.FC = () => {
  const { t } = useTranslation();
  const handleScrollTo = (targetId: string) => {
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      const topPosition = targetElement.offsetTop - 100;
      window.scrollTo({ top: topPosition, behavior: 'smooth' });
    } else {
      window.location.href = `/#${targetId}`;
    }
  };

  return (
    <Box mt={4} mb={6}>
      <Typography variant="h3" align="center" gutterBottom>
        {t('nav.service')}
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom component="h1">
            {t('service.sec1.ttl')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={0}>
            <img
              src={MadoriImage}
              alt="Madori"
              style={{ width: '100%', marginBottom: '1rem' }}
            />
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={0}>
            <GoldenRatioImage src={WhiteBoardImage} alt="WhiteBoard" />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            {t('service.sec2.ttl')}
          </Typography>
          <Typography paragraph>{t('service.sec2.txt1')}</Typography>
          <Typography paragraph>{t('service.sec2.txt2')}</Typography>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            {t('service.sec3.ttl')}
          </Typography>
          <Typography paragraph>{t('service.sec3.txt1')}</Typography>
          <Typography paragraph>{t('service.sec3.txt2')}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={0}>
            <GoldenRatioImage src={ProjectorImage} alt="Projector" />
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={0}>
            <GoldenRatioImage src={LockerImage} alt="Locker" />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            {t('service.sec4.ttl')}
          </Typography>
          <Typography paragraph>{t('service.sec4.txt1')}</Typography>
          <Typography paragraph>{t('service.sec4.txt2')}</Typography>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            {t('service.sec5.ttl')}
          </Typography>
          <Typography paragraph>{t('service.sec5.txt1')}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={0}>
            <GoldenRatioImage
              src={AirConditioningImage}
              alt="AirConditioning"
            />
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={0}>
            <GoldenRatioImage src={WifiImage} alt="Wifi" />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            {t('service.sec6.ttl')}
          </Typography>
          <Typography paragraph>{t('service.sec6.txt1')}</Typography>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            {t('service.sec7.ttl')}
          </Typography>
          <Typography paragraph>{t('service.sec7.txt1')}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={0}>
            <GoldenRatioImage src={RestRoomImage} alt="RestRoom" />
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={0}>
            <GoldenRatioImage src={PowerStrip1Image} alt="PowerStrip" />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            {t('service.sec8.ttl')}
          </Typography>
          <Typography paragraph>{t('service.sec8.txt1')}</Typography>
          <Typography paragraph>{t('service.sec8.txt2')}</Typography>
          <Typography paragraph>{t('service.sec8.txt3')}</Typography>
          <Typography paragraph>{t('service.sec8.txt4')}</Typography>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            {t('service.sec9.ttl')}
          </Typography>
          <Typography paragraph>{t('service.sec9.txt1')}</Typography>
          <Typography paragraph>{t('service.sec9.txt2')}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={0}>
            <GoldenRatioImage src={PowerStrip2Image} alt="PowerStrip" />
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={0}>
            <GoldenRatioImage
              src={MultifunctionDeviceImage}
              alt="MultifunctionDevice"
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            {t('service.sec10.ttl')}
          </Typography>
          <Typography paragraph>{t('service.sec10.txt1')}</Typography>
          <Typography paragraph>
            <Link
              onClick={() => handleScrollTo('price')}
              style={{ cursor: 'pointer' }}
            >
              {t('service.sec10.txt2')}
            </Link>
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            {t('service.sec11.ttl')}
          </Typography>
          <Typography paragraph>{t('service.sec11.txt1')}</Typography>
          <Typography paragraph>{t('service.sec11.txt2')}</Typography>
          <Typography paragraph>{t('service.sec11.txt3')}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={0}>
            <GoldenRatioImage src={EntranceImage} alt="Entrance" />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ServiceSection;
