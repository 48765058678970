import React, { useRef, useEffect } from 'react';
import { Box } from '@mui/material';
import FyraSuiteImage from '../assets/waku2.webp';

const HomeSection: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const imageRef = useRef(new Image());

  useEffect(() => {
    const canvas = canvasRef.current;
    const image = imageRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const setCanvasSize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    const drawImage = () => {
      if (ctx) {
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
      }
    };

    image.src = FyraSuiteImage;
    image.onload = () => {
      setCanvasSize();
      drawImage();
    };

    const handleResize = () => {
      setCanvasSize();
      drawImage();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Box
      mt={4}
      mb={6}
      sx={{
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <canvas ref={canvasRef} style={{ display: 'block' }} />
    </Box>
  );
};

export default HomeSection;
