import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ReservationSection: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box mt={4} mb={6}>
      <Typography variant="h3" align="center" gutterBottom>
        {t('nav.reservation')}
      </Typography>
      <Typography style={{ marginBottom: '1rem' }}>
        <Link
          href="https://spacemarket.com/p/iWe0aXKX5dJzRyL8"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('reservation.sec1.txt1')}
        </Link>
      </Typography>
      <Typography>{t('reservation.sec1.txt2')}</Typography>
      <Typography>{t('reservation.sec1.txt3')}</Typography>
      <Typography style={{ marginBottom: '1rem' }}>
        {t('reservation.sec1.txt4')}
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <iframe
          src="https://calendar.google.com/calendar/embed?src=waku2%40genpls.com&ctz=Asia%2FTokyo"
          style={{ border: 0 }}
          width="800"
          height="600"
          frameBorder="0"
          scrolling="no"
        ></iframe>
      </Box>
    </Box>
  );
};

export default ReservationSection;
