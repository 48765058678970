import React from 'react';
import {
  Box,
  Typography,
  //Link,
  Grid,
  List,
  ListItem,
  ListItemText,
  Card,
  CardContent,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const PriceSection: React.FC = () => {
  const { t } = useTranslation();
  const plans = [
    {
      name: t('price.plans.weekdays.name'),
      price: t('price.plans.weekdays.price'),
      features: [
        t('price.plans.weekdays.feature1'),
        t('price.plans.weekdays.feature2'),
      ],
    },
    {
      name: t('price.plans.holiday.name'),
      price: t('price.plans.holiday.price'),
      features: [
        t('price.plans.holiday.feature1'),
        t('price.plans.holiday.feature2'),
      ],
    },
    {
      name: t('price.plans.copy.name'),
      price: t('price.plans.copy.price'),
      features: [],
    },
    {
      name: t('price.plans.colorcopy.name'),
      price: t('price.plans.colorcopy.price'),
      features: [],
    },
  ];

  return (
    <Box mt={4} mb={6}>
      <Typography variant="h3" align="center" gutterBottom>
        {t('nav.price')}
      </Typography>
      <Grid>
        <Grid container spacing={3}>
          {plans.map((plan, index) => (
            <Grid item xs={12} sm={6} md={6} key={index}>
              <Card>
                <CardContent>
                  <Typography variant="h5" component="h3">
                    {plan.name}
                  </Typography>
                  <Typography variant="h6" color="primary">
                    {plan.price}
                  </Typography>
                  <List>
                    {plan.features.map((feature, idx) => (
                      <ListItem key={idx}>
                        <ListItemText primary={feature} />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default PriceSection;
